<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      Campaign Form
    </template>
    <template slot="body">
      <ValidationObserver ref="validator">
        <form
          ref="form"
        >
          <form-text
            v-model="form.name"
            icon="description"
            label="Name"
            rules="required"
          />
          <SegmentsSelect
            v-model="form.segment_id"
            rules="required"
          />
          <AgentsSelect
            v-model="form.administrator_id"
            label="Administrator"
            rules="required"
          />
          <FormDate
            v-model="form.expire_date"
            label="Expire Date"
            rules="required"
          />
          <FormText
            v-model="form.custom_fields"
            icon="label"
            label="Custom Fields (separated by semicolon)"
          />
          <FormText
            v-model="form.domain"
            icon="http"
            label="Domain"
            rules="required"
          />
        </form>
      </ValidationObserver>
    </template>
    <template slot="footer">
      <md-button
        class="md-success float-right"
        @click="saveItem"
      >
        <md-icon class="mr-2">
          save
        </md-icon>
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import {
  FormText,
  FormDate,
} from '@/components/Inputs';
import SegmentsSelect from '@/components/Inputs/selects/SegmentsSelect';
import { AgentsSelect } from '@/components/Inputs/selects';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', required);

export default {
  components: {
    FormText,
    FormDate,
    SegmentsSelect,
    AgentsSelect,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        name: null,
        segment_id: null,
        administrator_id: null,
        expire_date: null,
        custom_fields: null,
        domain: null,
      },
      selectingAgent: false,
      selAgent: null,
    };
  },
  mounted() {
    this.form = { ...this.item };
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    checkDomain() {
      const domainRegex = /^(http:\/\/|https:\/\/)[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
      if (!domainRegex.test(this.form.domain)) {
        this.fireError('Domain is not valid');
        return false;
      }

      return true;
    },
    async saveItem() {
      // if (!this.checkDomain()) return;

      await this.$refs.validator.validate().then(async (res) => {
        if (res) {
          if (this.form.campaign_id) {
            this.request(`crm/campaigns/${this.form.campaign_id}`, 'put', this.form, () => {
              this.fireSuccess('Campaign Updated Succesfully');
              this.$emit('save');
              this.close();
            });
          } else {
            this.request('crm/campaigns', 'post', this.form, () => {
              this.fireSuccess('Campaign Created Succesfully');
              this.$emit('save');
              this.close();
            });
          }
        } else {
          this.fireError('Complete all the required fields');
        }
      });
    },
  },
};
</script>
<style></style>
