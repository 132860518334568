<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="onCreate"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Item</md-tooltip>
            </md-button>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getItems"
              @onEdit="onEdit"
              @onDelete="onDelete"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <CampaignsModal
      v-if="selectedItem"
      :item="selectedItem"
      @close="closeModal"
      @save="$refs.vtable.init()"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import CampaignsModal from './CampaignsModal.vue';

export default {
  components: {
    VueTable,
    CampaignsModal,
  },
  props: [],
  data() {
    return {
      selectedItem: null,
      vTable: {
        headers: [
          {
            title: 'name',
            sortable: true,
          },
          {
            title: 'segment.name',
            mask: 'segment',
            sortable: true,
          },
          {
            title: 'administrator.name',
            mask: 'administrator',
            sortable: true,
          },
          {
            title: 'expire_date',
            mask: 'expire date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'custom_fields',
            mask: 'custom fields',
          },
          {
            title: 'campaign_token',
            mask: 'token',
            sortable: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit Item',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Item',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getItems(params) {
      this.request('crm/campaigns', 'get', { ...params }, ({ data }) => {
        this.vTable.values = data;
      });
    },
    onEdit(item) {
      this.selectedItem = { ...item };
    },
    onCreate() {
      this.selectedItem = {
        name: null,
        segment_id: null,
        administrator_id: null,
        expire_date: null,
        custom_fields: null,
        domain: null,
      };
    },
    closeModal() {
      this.selectedItem = null;
    },
    onDelete(item) {
      this.fireConfirm(
        'Deleting Campaign',
        `Are you sure you want to delete this Campaign "${item.name}"? All the associations will be deleted`,
      )
        .then(() => {
          this.request(`crm/campaigns/${item.campaign_id}`, 'delete', {}, () => {
            this.fireSuccess('Campaign deleted successfully.');
            // Reset Table after response
            this.$refs.vtable.init();
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
</style>
